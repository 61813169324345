<template>
  <div>
    <h1 class="headline--md text-bold text-uppercase">convert toko</h1>

    <div class="box box--sm box--gray radius-bottom-0 mt-16 d-flex">
      <multiple-select
        :value="filterValues"
        :options="options"
        @filter-callback="onGetConvertTokoOrdersByFilter($event)"
      />

      <div class="d-flex mr-16">
        <div class="search-box-group mr-8">
          <search-box v-model="searchParams.searchText" holder-text="Search" @on-search="onGetConvertTokoOrders" />

          <selection-box
            v-model="searchParams.searchTarget"
            :options="searchTargetOptions"
            :is-align-right="true"
            :on-check="onGetConvertOrdersByTarget"
            selected-box-class="is-lg search-box__filter"
          />
        </div>

        <button class="btn btn--md btn--primary mr-8" @click="onGetConvertTokoOrders">Search</button>
      </div>

      <selection-box
        v-model="params.perPage"
        :options="pagerOptions"
        :is-align-right="true"
        :on-check="onGetConvertTokoOrders"
      />
    </div>

    <div
      v-if="convertTokoOrders && convertTokoOrders.data && convertTokoOrders.data.length > 0"
      class="table-wrapper table-wrapper--fluid table-wrapper--expand radius-top-0"
    >
      <table class="table table--striped">
        <thead>
          <tr>
            <th>Email</th>
            <th>Wallet Address</th>
            <th>From -> To</th>
            <th>Convert Amount (TOKO)</th>
            <th>Create Time<br />UTC</th>
            <th>Status</th>
          </tr>
        </thead>

        <tbody>
          <router-link
            v-for="order in convertTokoOrders.data"
            :key="order.id"
            :to="{ name: 'ConvertTokoOrder', params: { id: order.id } }"
            tag="tr"
            class="link--clone"
          >
            <td class="clipboard-copy">
              <span class="clipboard-copy__text">
                <span class="clipboard-copy__text-inner">{{ order.user.email }}</span>
                <copy-clipboard :is-external="false" @on-copy="doCopy($event)" />
              </span>
            </td>
            <td class="wr-20 clipboard-copy">
              <span class="clipboard-copy__text">
                <span class="clipboard-copy__text-inner">{{ order.userWalletAddressFrom }}</span>
                <copy-clipboard :is-external="false" @on-copy="doCopy($event)" />
              </span>
            </td>
            <td>
              <div v-if="order.from_network === 1" class="d-flex"><toko-eth-icon /> > <toko-bsc-icon /></div>

              <div v-else class="d-flex"><toko-bsc-icon /> > <toko-eth-icon /></div>
            </td>
            <td>{{ order.amount | float_number_with_commas }}<br />TOKO</td>
            <td v-html="getMomentLTSFormatted(order.adminReceivedAtMsc)" />
            <td v-html="formatConvertTokoOrderStatus(order.status)" />
          </router-link>
        </tbody>
      </table>

      <div class="text-center mt-32">
        <p>{{ convertTokoOrders.total | format_order_count_text }} in total</p>

        <pagination
          v-if="isNextPage(convertTokoOrders.total)"
          v-model="page"
          :page-count="getPageCount(convertTokoOrders.totalPages, convertTokoOrders.total)"
          :page-range="5"
          :click-handler="clickPagerCallback"
        />
      </div>
    </div>

    <p v-else class="mt-16">No data</p>

    <loading :open="!isLoaded" />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { TNX_NETWORK_TYPES } from '@/utils/key-mapping'
import { PAGER_OPTIONS, CONVERT_TOKO_ORDER_STATUS_OPTIONS, TNX_NETWORK_TYPE_OPTIONS } from '@/utils/data-sets'
import toggleLoading from '@/mixins/toggle-loading'
import pagination from '@/mixins/pagination'
import momentltsFormat from '@/mixins/momentlts-format'
import copyClipboard from '@/mixins/copy-clipboard'
import handleAssets from '@/mixins/handle-assets'
import handleFilterBox from '@/mixins/handle-filter-box'
import convertTokoOrderStatus from './-mixins/convert-toko-order-status'
const MultipleSelect = () => import('@/components/MultipleSelect')
const CopyClipboard = () => import('@/components/CopyClipboard')
const Pagination = () => import('@/components/Pagination')
const SelectionBox = () => import('@/components/SelectionBox')
const SearchBox = () => import('@/components/SearchBox')
const TokoBscIcon = () => import('@/ui/toko-icons/TokoBSC')
const TokoEthIcon = () => import('@/ui/toko-icons/TokoETH')

export default {
  data() {
    return {
      options: [
        {
          label: 'Network Type',
          hint: 'networkType',
          options: TNX_NETWORK_TYPE_OPTIONS
        },

        {
          label: 'Status Filter',
          options: CONVERT_TOKO_ORDER_STATUS_OPTIONS
        }
      ],
      filterValues: [],
      searchParams: {
        searchText: '',
        searchTarget: 1
      },
      params: {
        perPage: 10,
        networkType: TNX_NETWORK_TYPES.invalid
      },
      page: 1,
      pagerOptions: PAGER_OPTIONS,
      searchTargetOptions: [
        { value: 0, name: 'Amount TOKO Swap', disabled: false },
        { value: 1, name: 'Email', disabled: false },
        { value: 2, name: 'Wallet Address', disabled: false }
      ]
    }
  },

  mixins: [
    toggleLoading,
    pagination,
    momentltsFormat,
    copyClipboard,
    handleFilterBox,
    handleAssets,
    convertTokoOrderStatus
  ],

  components: {
    CopyClipboard,
    Pagination,
    SearchBox,
    SelectionBox,
    MultipleSelect,
    TokoBscIcon,
    TokoEthIcon
  },

  computed: {
    ...mapState('convertToko', ['convertTokoOrders', 'assets']),

    searchTarget() {
      return parseInt(this.searchParams.searchTarget)
    }
  },

  methods: {
    ...mapActions('convertToko', ['getConvertTokoOrders']),

    ...mapMutations('convertToko', ['STORE_ASSETS', 'CLEAR_ASSETS']),

    onGetConvertOrdersByTarget() {
      if (!this.searchParams.searchText) return
      this.onGetCrowdfundingFeeds()
    },

    onGetConvertTokoOrders() {
      this.toggleLoading()
      this.page = 1
      this.getConvertTokoOrders(this.getConvertTokoOrderParams()).finally(() => {
        this.toggleLoading()
      })
    },

    onGetConvertTokoOrdersByFilter(values) {
      this.filterValues = values
      for (let i = 0; i < values.length; i++) {
        if (values[i].category === 'networkType') {
          this.params.networkType = values[i].value
        }
      }

      this.onGetConvertTokoOrders()
    },

    clickPagerCallback() {
      this.toggleLoading()
      this.getConvertTokoOrders(this.getConvertTokoOrderParams()).finally(() => {
        this.toggleLoading()
      })
    },

    getConvertTokoOrderParams() {
      const params = {
        ...this.statuses,
        page: this.page,
        limit: this.params.perPage,
        order_by: this.orderBy.orderBy,
        sort_by: this.orderBy.sortBy,
        from_network: this.params.networkType
      }

      if (this.searchParams.searchText) {
        if (this.searchTarget === 0) params.amount = this.searchParams.searchText
        if (this.searchTarget === 1) params.email = this.searchParams.searchText
        if (this.searchTarget === 2) params.userWalletAddressFrom = this.searchParams.searchText
      }

      return params
    }
  },

  async created() {
    try {
      await this.getConvertTokoOrders(this.getConvertTokoOrderParams())
      this.toggleLoading()
    } catch (e) {
      this.toggleLoading()
      throw e
    }
  },

  beforeRouteLeave(to, from, next) {
    if (to.name === 'ConvertTokoOrder') {
      this.storeAssets('convertToko', 'STORE_ASSETS')
      next()
    } else {
      this.clearAssets('convertToko', 'CLEAR_ASSETS')
      next()
    }
  }
}
</script>
